<template>
  <v-container fluid>
    <v-row v-if="project">
      <v-col cols="3">
        <h1>{{ this.project.title }}</h1>
        <v-img class="no-aa"
               :src="'/api/projects/preview/' + id + '?mode=real&scale=20&v=2'"
               style="max-width: 100%;max-height: 100%;background-color: rebeccapurple;"
               contain/>
      </v-col>
      <v-col>
        <br/>
        <br/>

        <v-btn
          color="blue lighten-1" @click="exportProject()"> {{ $t("common.export_complectation") }}
        </v-btn>
        <br/>
        <br/>
        <v-btn
          color="blue lighten-1" @click="exportPNG()">{{ $t("common.export_png") }}
        </v-btn>
        <br/>
        <br/>
        <v-btn
          color="blue lighten-1" @click="exportInterior()">Interior
        </v-btn>
        <br/>
        <br/>
        <v-btn
          :disabled="true"
          color="blue lighten-1" @click="exportArchive()">Export Archive (soon)
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Preview",
  data() {
    return {
      project: null,
      id: 0
    }
  },
  computed: {
    // ...mapGetters(['adminProjects', 'adminProjectsPages'])
  },
  methods: {
    exportProject() {
      // let routeData = this.$router.resolve({name: 'admin_project_export', params: {id: this.id}});
      let routeData = this.$router.resolve({name: 'admin_project_print', params: {id: this.id}});
      window.open(routeData.href, '_blank');
    },
    exportArchive() {
      // let routeData = this.$router.resolve({name: 'admin_project_print', params: {id: this.id}});
      // window.open(routeData.href, '_blank');
      let routeData = '/api/projects/export/' + this.id;
      console.log(routeData)
      window.open(routeData, '_blank');
    },

    exportPNG() {
      // let routeData = this.$router.resolve({name: 'admin_project_export', params: {id: this.id}});
      let routeData = '/api/projects/preview/' + this.id + '?mode=real&scale=50&download=yes';
      console.log(routeData)
      window.open(routeData, '_blank');
    },
    exportInterior() {
      // let routeData = this.$router.resolve({name: 'admin_project_export', params: {id: this.id}});
      let routeData = '/api/projects/preview/' + this.id + '?mode=real&scale=20&download=yes&interior=yes';
      console.log(routeData)
      window.open(routeData, '_blank');
    },
    async getDataFromApi() {
      this.$store.commit('app/setLoading', true)
      const {project} = await this.$api.projects.getProject({project_id: this.id});
      this.project = project
      this.$store.commit('app/setLoading', false)
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getDataFromApi();
  }

}
</script>

<style lang="scss" scoped>

</style>
